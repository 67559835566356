@import '../../../scss/_base';

.mobileNotificationModalWrapper{
  @include themify($themes) {
    font-family: themed(font-family);
    width: 279px;
  }
  .modalHeader{
    font-size: 17px;
    line-height: 45px;
    width: 279px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
      color: $white;
    }
  }
  .modalContent {
    width: 100%;
    font-size: 15px;
    background-color: #f1ede5;
    .content{
      width: 100%;
      overflow-wrap: anywhere;
      overflow-y: scroll;
      min-height: 80px;
      max-height: 250px;
      .messageItem{
        padding: 5px;
        background-color: white;
        margin: 5px;
        &>div:last-child{
          padding-top: 5px;
        }
        &>div:first-child{
          padding-bottom: 5px;
          border-bottom: 1px dotted #999;
        }
      }

    }
  }

  .modalFooter{
    border-top: 1px solid #dcdcdc;
    font-size: 15px;
      svg{
        @include themify($themes){
          color:themed(theme-primary-color) !important;
        }
       }
    .confirmButton{
      border-radius: 4px;
      @include themify($themes) {
        border: 0px;
        background-color: themed('theme-primary-color');
        color: $white;
      }
    }
    .promotionButton{
      border-radius: 4px;
      @include themify($themes) {
        border: 0px;
        background-color: themed('theme-primary-color');
        color: $white;
      }
    }
  }
}