@import '../../../scss/web_base';


.loginAndRegister{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  .loginNoAccount{
      display: flex;
      padding-left: 20px;
      span{
          @include themify($themes){
              color: themed(theme-primary-color);
          }
          cursor: pointer;
      }
      &>div{
          flex: 1;
          text-align: end;
          padding-right: 20px;
          cursor: pointer;
      }
  }
  .loginBox{
      width: 446px;
      background-color: white;
      padding: 30px 40px;
      border-radius: 20px;
      .loginTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
          width: 120px;
        }
          @include themify($themes){
              color: themed(theme-secondary-color);
          }
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 30px;
      }
      .loginBoxItem{
          margin-bottom: 20px;
          height: 45px;
          border-radius: 30px;
          width: 100%;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          .loginButton{
              @include themify($themes){
                  background-color: themed(theme-primary-color);
              }
              &:hover{
                  @include themify($themes){
                      background-color: themed(theme-secondary-color) !important;
                  }
              }
              height: 45px !important;
              width: 100%;
              border-radius: 30px;
              border: none !important;
              font-weight: bold;
          }
          &>div:first-child{
              font-weight: bold;
              padding-left: 20px;
              @include themify($themes){
                  color: themed(theme-primary-color);
              }
              width: 30%;
          }
          input{
              flex: 1;
              border: none !important;
              background-color: transparent !important;
              &:focus{
                  box-shadow: none !important;
              }
          }
          #captchaImg{
              height: 43px !important;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
          }
      }
  }

}

.successModalWrapperWeb {
  width: 500px !important;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding-bottom: 2em;
    .successTick {
      height: 7em;
      width: 7em;
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    @include themify($themes) {
      border-top: 1px solid themed(success-modal-footer-border-color)
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}
.successModalWrapperMobile {
  @include themify($themes) {
    font-family: themed(font-family);
    width: 100%;
  }
  .modalHeader{
    line-height: 45px;
    width: 100%;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
      color: $white;
    }
  }
  .modalContent {
    width: 100%;
    .row{
      width: 100%;
    }
    .successTick{
      height: 80px;
    }
  }
  .modalFooter{
    width: 100%;
    .confirmButton{
      width: 100px;
      @include themify($themes) {
        border: 0px;
        background-color: themed('theme-primary-color');
        color: $white;
      }
    }
  }
}