@import '../../../scss/web_base';

.topUpPanelWrapperWeb {
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding-bottom: 2em;
    .successTick {
      height: 7em;
      width: 7em;
    }
    .row {
      display: flex;
      width: 100%;
      justify-content: center;
      &.orderIdWrapper {
        @include themify($themes) {
          color: themed(top-up-modal-content-orderId-text-color);
        }
        letter-spacing: 2px;
        font-weight: bold;
      }
      &.attentionMessage {
        font-size: 13px;
        font-weight: bold;
        .attentionText {
          @include themify($themes) {
            color: themed(top-up-modal-content-attention-text-color);
          }
        }
      }
      .detailTable {
        width: 55%;
        border: none;
        tbody {
          tr {
            td {
              padding: .3rem .75rem;
              border: none;
              &:first-child {
                width: 35%;
              }
              &:last-child {
                width: 25%;
              }
              &.label {
                @include themify($themes) {
                  color: themed(top-up-modal-content-label-text-color)
                }
              }
              &.value {
                @include themify($themes) {
                  color: themed(top-up-modal-content-value-text-color)
                }
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    .reminderMessage,
    .successMessage {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: bold;
      font-size: 14px;
      @include themify($themes) {
        color: themed(top-up-modal-content-message-text-color);
        background: themed(top-up-modal-content-message-background-color);
      }
    }
    .successMessage {
      width: 320px
    }
    .reminderMessage {
      width: 435px;
    }
    .completeTopUpButton {
      width: 120px;
      height: 50px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      line-height: 36px;
      padding: 0 .75rem;
      @include themify($themes){
        background: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
    .copyButton {
      @include themify($themes) {
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
      font-size: 16px;
      padding: 1px 15px;
    }
    .cancelButton {
      @include themify($themes) {
        background-color: themed(top-up-modal-cancel-order-background-color);
      }
      width: 120px;
      height: 50px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      line-height: 36px;
      padding: 0 .75rem;
      color: $white;
      border: 0;
    }
  }
}
.topUpPanelWrapperMobile {
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 13px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding-bottom: 2px;
    width: 300px;
    .successTick {
      height: 5em;
      width: 5em;
    }
    .row {
      display: flex;
      width: 100%;
      justify-content: center;
      &.orderIdWrapper {
        letter-spacing: 2px;
        font-weight: bold;
      }
      &.attentionMessage {
        font-size: 13px;
        font-weight: bold;
        .attentionText {
          @include themify($themes) {
            color: themed(top-up-modal-content-attention-text-color);
          }
        }
      }
      .detailTable {
        width: 100%;
        border: none;
        tbody {
          tr {
            td {
              padding: 0px;
              vertical-align: middle;
              border: none;
              &:first-child {
                width: 30%;
              }
              &:last-child {
                width: 15%;
              }
              &.label {
                padding: 0 2px 0;
                @include themify($themes) {
                  color: themed(top-up-modal-content-label-text-color)
                }
              }
              &.value {
                text-align: center;
              }
              .copyButton {
                float: right;
                font-size: 12px;
                @include themify($themes) {
                  background-color: themed(theme-primary-color)
                }
                color: $white;
                border: 0px;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
    .reminderMessage,
    .successMessage {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: bold;
      font-size: 14px;
      @include themify($themes) {
        color: themed(top-up-modal-content-message-text-color);
      }
    }
    .successMessage {
      width: 320px
    }
    .reminderMessage {
      width: 100%;
    }
    .topBorder{
      @include themify($themes){
        border-top: 1px solid themed(past-result-list-border-color);
      }
      .reminderWrapper{
        text-align: start;
      }
      .yellowText {
        @include themify($themes){
          color: themed(reminder-text)
        }
      }
    }
    .completeTopUpButton {
      width: 120px;
      height: 50px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      line-height: 36px;
      padding: 0 .75rem;
      @include themify($themes){
        background: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
    .copyButton {
      @include themify($themes) {
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
      font-size: 16px;
      padding: 1px 15px;
    }
    .cancelButton {
      @include themify($themes) {
        background-color: themed(top-up-modal-cancel-order-background-color);
      }
      width: 120px;
      height: 50px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      line-height: 36px;
      padding: 0 .75rem;
      color: $white;
      border: 0;
    }
  }
}