@import '../../../scss/base';

.mobileOnlineRadioListWarpper {
  height: 100%;

  .modalHeader {
    justify-content: center;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
    }
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.2em;
    font-weight: bold;
  }

  .modalBodyWrapper{
    overflow-y: scroll;
    max-height: 400px;
    .row {
      width: 100%;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      margin: 0;
      border-bottom: 1px solid $border;
      font-size: 15px;
      width: 279px;
      .radioButtonItem {
        svg {
          margin-top: 5px;
          font-size: 1.3em;
          @include themify($themes) {
            fill: themed(theme-primary-color);
            stroke: themed(theme-primary-color);
          } 
        }
      }
    }
    .disableClass {
      background-color:rgb(240, 240, 240);
      color:#7e7e7e;
    }
  }
  
  .modalFooter{
    .button{
      width: 100px;
      @include themify($themes) {
        border: 0px;
        background-color: themed(theme-primary-color);
        color: $white;
      }
    }
  }
}