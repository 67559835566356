@import '~antd/dist/antd.css';
html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family:Helvetica,Tahoma, Arial,"PingFang SC",STXihei,"Microsoft yahei","WenQuanYi Micro Hei",sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
  font-size: 13px !important;
  line-height: 1.5 !important;
}

#root {
  height: 100%;
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 910px !important;
  }

  .authContent {
    .contentContainer  {
      width: 1388px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .authContent {
    .contentContainer  {
      width: 1388px !important;
    }
  }
}

@media (min-width: 768px) {
  .authContent {
    .contentContainer  {
      width: 1388px !important;
    }
  }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1200px) { 
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 850px !important;
  }
}
