$white: #FFFFFF;
$black: #000000;
$grey: #808080;
$red: #FF0000;
$themeBlue: #448EED;
$themeRed: #D37887;
$themeGreen: #95C15F;
$themeYellow: #EBA717;
$fontFamily: Helvetica,Tahoma, Arial,"PingFang SC",STXihei,"Microsoft yahei","WenQuanYi Micro Hei",sans-serif;
$ballRed: #EC6941;
$ballBlue: #5EA1F7;
$ballGreen: #80C269;
$ballYellow: #F5E204;
$place-holder-text-color: #CECECE;
$fanTanTopBottom: #CBE6EC;
$fanTanLeftRight: #FFE658;
$fanTanCorner: #FFE69B;

$highFrequencyColor: #FE9966;
$speedGameColor: #F9A833;
$liuHeColor: #97C49C;
$fantanColor: #F39696;
$luckyColor: #B397EC;
$luckyIIColor: #517ED0;
$allGameColor: #5CA1F7;

$menuActiveColor: #EB8049;

$themes: (
    'blue':(
        font-family: $fontFamily,
        theme-primary-color: #448EED,
        theme-secondary-color: #3D85DF,
        theme-third-color: #EAF3FF,
        sidebar-betting-title-bg-color: #8AC775,
        sidebar-betting-second-title-bg-color: #FFBE68,
        today-win-bg-color: #5FA1F7,
        footer-more-color: #3D85DF,
        past-result-current-installment-color:#FFDC1C,
        past-result-list-installments-color:#448EED,
        past-result-list-border-color:#DCDCDC,
        content-menu-color: #FFBD68,
        win-lose-amount-color: #1256B1,
        red-num-color: #f96C41,
        blue-num-color: #5EA1F7,
        green-num-color: #8AC775,
        plus-color: #FA7A53,
        header-menu-hover-selected-color: #FCD609,
        sidebar-lottery-color: #5A40FB,
        sidebar-odds-color: #FF4056,
        sidebar-border-color: #CCD3DA,
        header-game-separator: #FFFFFF33,
        table-border-color:#DCDCDC,
        table-header-text-color:#5D6D7B,
        reset-button-color:#8CC878,
        clear-button-color:#EC6941,
        table-cell-color:#F7F7F7, 
        table-odds-color:#F96C41,
        table-square-1:#FFCE00,
        table-square-2:#2C39CA,
        table-square-3:#6F6A66,
        table-square-4:#FF9837,
        table-square-5:#2DCEDE,
        table-square-6:#7655B9,
        table-square-7:#A9A9A9,
        table-square-8:#F54141,
        table-square-9:#792020,
        table-square-10:#4CC32D,
        quick-bet-label-color:#5A6167,
        quick-bet-input-text-color:#B7B7B7,
        quick-bet-border-color:#DCDCDC,
        games-background-color: #EDEDED,
        table-ssc-circle-text-color: #626262,
        table-ssc-circle-border-color: #5EA1F7,
        unsettled-sum-color: #D4e4F9,
        settled-unsettled-row-no:#1E70DC,
        settled-unsettled-installment:#81C269,
        settled-unsettled-bet-content:#5FA1F6,
        settled-unsettled-bet-odds:#F96C41,
        settled-unsettled-win-money:#5FA1F6,
        settled-unsettled-lose-money:#F96C41,
        pagination-text-color:#666,
        pagination-border-color:#DDD,
        game-menu-title-border: #E4E3E6,
        vipcard-title-color: #5D6D7B,
        chang-long-background-color: #FAFAFA,
        chang-long-installment-color: #F96C41,
        chang-long-border-color: #DCDCDC,
        active-game-background-color: #E6E6E6,
        inactive-bell-background-color: #808887,
        modal-footer-border-color: #DDDDDD,
        reset-default-text-color: #6C727A,
        user-center-button-group-background-color: #F3DDCD,
        user-center-button-background-color: #F95858,
        user-center-top-up-button-background-color: #F9AC00,
        inactive-game-text-color: #666666,
        game-hover-fill-color: #F52C26,
        bet-history-btn-hover:#F9AC00,
        bet-history-arrow-color:#458EED,
        confirm-bet-secondary-color: #EAF3FF,
        confirm-bet-footer-button-color: #6EAEFF,
        confirm-bet-content-installment-text-color: #A5A9AD,
        confirm-bet-table-border-color: #DCDCDC,
        confirm-bet-win-amount: #C1C2C1,
        confirm-bet-balance-amount: #68747F,
        confirm-bet-lottery-text: #68747F,
        bet-items-label-selected-background-color: #FBF7CB,
        bet-items-odds-selected-background-color: #FFF694,
        scrollbar-color: #ADACAC,
        result-red-text:#F96C41,
        result-green-text:#81C269,
        result-blue-text: #5FA1F6,
        probability-border-color:#DCDCDC,
        tab-header-hover-background-color: #CCDEF5,
        shortcut-input-background-color: #FDEFDD,
        shortcut-title-text-color: #77654C,
        shortcut-title-wrapper-color: #C3AF95,
        game-menu-hover-background-color: #1C65BF,
        game-menu-active-text-color: #FFE100,
        quickbet-button-color: #FF9C1C,
        leng-re-option-border-color: #DCDCDC,
        leng-re-inactive-text-color: #B4B4B4,
        leng-re-active-text-color: #EC6941,
        leng-re-checkbox-border-color: #DCDCDC,
        checkbox-option-border-color:#DCDCDC,
        section-label-background-color: #EAF3FF,
        section-label-text-color: #5D6D7B,
        confirm-redemption-background-color: #6EAEFF,
        result-table-header-text:#5D6D7B,
        result-table-row-text:#5A6167,
        result-border:#D2D7E0,
        lottery-rules-border-color: #D2D7E0,
        safety-centre-input-text-color: #444444,
        safety-centre-button-text-color: #555555,
        safety-centre-button-disabled: #E9ECEF,
        betLimitBasicInfoHighlighted: #FFF582,
        balance-log-modal-hover-color: #F0F6FF,
        lottery-rules-subHeader-font-color: #212529,
        lottery-rules-red-font-color: #FF001D,
        error-modal-footer-border-color: #DCDCDC,
        error-modal-text-color: #DD4B4B,
        success-modal-footer-border-color: #DCDCDC,
        success-modal-text-color: #67A145,
        top-up-modal-cancel-order-background-color: #FF9C1C,
        top-up-modal-copy-button-background-color: #F9AB02,
        top-up-modal-confirm-button-background-color: #5DA0F6,
        top-up-modal-content-message-background-color: #ECEDED,
        top-up-modal-content-message-text-color: #677C88,
        top-up-modal-content-label-text-color: #8A8B8B,
        top-up-modal-content-value-text-color: #478EED,
        top-up-modal-content-orderId-text-color: #F95959,
        top-up-modal-content-attention-text-color: #F95959,
        top-up-payment-type-item-border-color: #DCDCDC,
        top-up-payment-type-item-text-color: #555555,
        modal-background-color: #6EAEFF,
        table-hk6-tab-border-color: #5EA1F7,
        checkbox-disabled-text-color: #ABABAB,
        checkbox-disabled-background-color: #E6E5E5,
        third-party-records-search-button-color: #448EED,
        third-party-records-search-button-hover-color: #F9AC00,
        third-party-records-sum-background-color: #F3F3F3,
        third-party-records-sum-positive-text-color: #5FA1F6,
        third-party-records-sum-negative-text-color: #FF001D,
        third-party-records-bet-id-text-color: #5FA1F6,
        bank-card-tips: #B9C3E4,
        bank-card-card-background: #7EAEFF,
        bank-card-card-border: #CCCCCC,
        bank-card-card-name: #4B689A,
        withdrawal-bank-card-bg: #7EAEFF,
        withdrawal-bank-card-border: #CCC,
        withdrawal-bank-card-name: #4B689A,
        withdrawal-type-btn-border: #DCDCDC,
        withdrawal-type-btn-color: #555,
        withdrawal-title: #5D6D7B,
        withdrawal-warning: #FF2B2B,
        transfer-border-refresh: #8AC775,
        transfer-confirm-btn-bg:#6eaeff,
        transfer-text:#5d6d7b,
        transfer-svg-stroke:#5687c6,
        transfer-bg:#eaf3ff,
        transfer-input-border-color: #BFCBD9,
        multiple-type-odds-tips: #5a6167,
        datatable-empty-data-text-color: #C7C7C7,
        transaction-id-text-color: #1E70DC,
        transaction-success-text-color: #81C269,
        transaction-fail-text-color: #FF001D,
        report-date-text-color: #1E70DC,
        report-detail-header-background-color: #E2EDFD,
        withdrawal-seleted-card-border: #D3F3A9,
        notification-unread-text-color: #FF001D,
        notification-unread-title-text-color: #1E70DC,
        notification-modal-label-cell-background: #EAF3FF,
        textarea-border-color: #DCDCDC,
        unread-notification-background-color: #F95858,
        change-password-row-header-text-color: #5D6D7B,
        change-password-confirm-button-background: #6EAEFF,
        change-password-input-error-text-color: #FF001D,
        side-bet-header-background-color: #EAF3FF,
        leng-re-value-hot-background-color: #FB4C93,
        leng-re-value-cold-background-color: #519BFB,
        leng-re-value-normal-background-color: #B7B7B7,
        yi-lou-value-hot-text-color: #FB4C93,
        yi-lou-value-cold-text-color: #519BFB,
        yi-lou-value-normal-text-color: #B7B7B7,
        quick-bet-disabled: #DCDCDC,
        quick-bet-selected: #FFF582DE,
        quick-bet-btn: #5EA1F7,
        quick-bet-input-label:#696969,
        latest-news-footer-button-color: #6EAEFF,
        changlong-question-icon-color: #697885,
        customise-changlong-modal-orange: #FE9C19,
        customise-changlong-modal-template-code-color: #B7B7B7,
        BJK8-ball-light: #96D2E4,
        BJK8-ball-text-color: #09485D,
        BJK8-ball-dark: #214B8A,
        fan-tan-corner-text-color: #D43164,
        fan-tan-label-text-color: #666666,
        hk6-choose-bet-cell:#F7F7F78A,
        game-notice-open-string-text-color: #008000,
        changlong-even-text-color: #00f,
        reminder-text: #FF9E27,
        leng-re-popover-content: #707E8A,
        balance-transfer-loading: #DCDCDC,
        choose-bet-label: #5A6167,
        probability-result-value-label: #5A6167,
        bank-card-bg:#7eaeff,
        bank-card-selected-border: #4F6ECD,
        nav-ico-color:#94BAE3,
    ),
    'red':(
        font-family: $fontFamily,
        theme-primary-color: #D37887,
        theme-secondary-color: #C87583,
        theme-third-color: #FEF4F6,
        sidebar-betting-title-bg-color: #8AC775,
        sidebar-betting-second-title-bg-color: #FFBE68,
        today-win-bg-color: #5FA1F7,
        footer-more-color: #3D85DF,
        past-result-current-installment-color:#FFDC1C,
        past-result-list-installments-color:#448EED,
        past-result-list-border-color:#DCDCDC,
        content-menu-color: #FFBD68,
        win-lose-amount-color: #1256B1,
        red-num-color: #f96C41,
        blue-num-color: #5EA1F7,
        green-num-color: #8AC775,
        plus-color: #FA7A53,
        header-menu-hover-selected-color: #FCD609,
        sidebar-lottery-color: #5A40FB,
        sidebar-odds-color: #FF4056,
        sidebar-border-color: #CCD3DA,
        header-game-separator: #FFFFFF33,
        table-border-color:#DCDCDC,
        table-header-text-color:#5D6D7B,
        reset-button-color:#8CC878,
        clear-button-color:#EC6941,
        table-cell-color:#F7F7F7, 
        table-odds-color:#F96C41,
        table-square-1:#FFCE00,
        table-square-2:#2C39CA,
        table-square-3:#6F6A66,
        table-square-4:#FF9837,
        table-square-5:#2DCEDE,
        table-square-6:#7655B9,
        table-square-7:#A9A9A9,
        table-square-8:#F54141,
        table-square-9:#792020,
        table-square-10:#4CC32D,
        quick-bet-label-color:#5A6167,
        quick-bet-input-text-color:#B7B7B7,
        quick-bet-border-color:#DCDCDC,
        games-background-color: #EDEDED,
        table-ssc-circle-text-color: #626262,
        table-ssc-circle-border-color: #5EA1F7,
        unsettled-sum-color: #D4e4F9,
        settled-unsettled-row-no:#1E70DC,
        settled-unsettled-installment:#81C269,
        settled-unsettled-bet-content:#5FA1F6,
        settled-unsettled-bet-odds:#F96C41,
        settled-unsettled-win-money:#5FA1F6,
        settled-unsettled-lose-money:#F96C41,
        pagination-text-color:#666,
        pagination-border-color:#DDD,
        game-menu-title-border: #E4E3E6,
        vipcard-title-color: #5D6D7B,
        chang-long-background-color: #FAFAFA,
        chang-long-installment-color: #F96C41,
        chang-long-border-color: #DCDCDC,
        active-game-background-color: #E6E6E6,
        inactive-bell-background-color: #808887,
        modal-footer-border-color: #DDDDDD,
        reset-default-text-color: #6C727A,
        user-center-button-group-background-color: #F3DDCD,
        user-center-button-background-color: #F95858,
        user-center-top-up-button-background-color: #F9AC00,
        inactive-game-text-color: #666666,
        game-hover-fill-color: #F52C26,
        bet-history-btn-hover:#F9AC00,
        bet-history-arrow-color:#458EED,
        confirm-bet-secondary-color: #EAF3FF,
        confirm-bet-footer-button-color: #6EAEFF,
        confirm-bet-content-installment-text-color: #A5A9AD,
        confirm-bet-table-border-color: #DCDCDC,
        confirm-bet-win-amount: #C1C2C1,
        confirm-bet-balance-amount: #68747F,
        confirm-bet-lottery-text: #68747F,
        bet-items-label-selected-background-color: #FBF7CB,
        bet-items-odds-selected-background-color: #FFF694,
        scrollbar-color: #ADACAC,
        result-red-text:#F96C41,
        result-green-text:#81C269,
        result-blue-text: #5FA1F6,
        probability-border-color:#DCDCDC,
        tab-header-hover-background-color: #CCDEF5,
        shortcut-input-background-color: #FDEFDD,
        shortcut-title-text-color: #77654C,
        shortcut-title-wrapper-color: #C3AF95,
        game-menu-hover-background-color: #1C65BF,
        game-menu-active-text-color: #FFE100,
        quickbet-button-color: #FF9C1C,
        leng-re-option-border-color: #DCDCDC,
        leng-re-inactive-text-color: #B4B4B4,
        leng-re-active-text-color: #EC6941,
        leng-re-checkbox-border-color: #DCDCDC,
        checkbox-option-border-color:#DCDCDC,
        section-label-background-color: #EAF3FF,
        section-label-text-color: #5D6D7B,
        confirm-redemption-background-color: #6EAEFF,
        result-table-header-text:#5D6D7B,
        result-table-row-text:#5A6167,
        result-border:#D2D7E0,
        lottery-rules-border-color: #D2D7E0,
        safety-centre-input-text-color: #444444,
        safety-centre-button-text-color: #555555,
        safety-centre-button-disabled: #E9ECEF,
        betLimitBasicInfoHighlighted: #FFF582,
        balance-log-modal-hover-color: #F0F6FF,
        lottery-rules-subHeader-font-color: #212529,
        lottery-rules-red-font-color: #FF001D,
        error-modal-footer-border-color: #DCDCDC,
        error-modal-text-color: #DD4B4B,
        success-modal-footer-border-color: #DCDCDC,
        success-modal-text-color: #67A145,
        top-up-modal-cancel-order-background-color: #FF9C1C,
        top-up-modal-copy-button-background-color: #F9AB02,
        top-up-modal-confirm-button-background-color: #5DA0F6,
        top-up-modal-content-message-background-color: #ECEDED,
        top-up-modal-content-message-text-color: #677C88,
        top-up-modal-content-label-text-color: #8A8B8B,
        top-up-modal-content-value-text-color: #478EED,
        top-up-modal-content-orderId-text-color: #F95959,
        top-up-modal-content-attention-text-color: #F95959,
        top-up-payment-type-item-border-color: #DCDCDC,
        top-up-payment-type-item-text-color: #555555,
        modal-background-color: #6EAEFF,
        table-hk6-tab-border-color: #5EA1F7,
        checkbox-disabled-text-color: #ABABAB,
        checkbox-disabled-background-color: #E6E5E5,
        third-party-records-search-button-color: #448EED,
        third-party-records-search-button-hover-color: #F9AC00,
        third-party-records-sum-background-color: #F3F3F3,
        third-party-records-sum-positive-text-color: #5FA1F6,
        third-party-records-sum-negative-text-color: #FF001D,
        third-party-records-bet-id-text-color: #5FA1F6,
        bank-card-tips: #B9C3E4,
        bank-card-card-background: #7EAEFF,
        bank-card-card-border: #CCCCCC,
        bank-card-card-name: #4B689A,
        withdrawal-bank-card-bg: #7EAEFF,
        withdrawal-bank-card-border: #CCC,
        withdrawal-bank-card-name: #4B689A,
        withdrawal-type-btn-border: #DCDCDC,
        withdrawal-type-btn-color: #555,
        withdrawal-title: #5D6D7B,
        withdrawal-warning: #FF2B2B,
        transfer-border-refresh: #8AC775,
        transfer-confirm-btn-bg:#6eaeff,
        transfer-text:#5d6d7b,
        transfer-svg-stroke:#5687c6,
        transfer-bg:#eaf3ff,
        transfer-input-border-color: #BFCBD9,
        multiple-type-odds-tips: #5a6167,
        datatable-empty-data-text-color: #C7C7C7,
        transaction-id-text-color: #1E70DC,
        transaction-success-text-color: #81C269,
        transaction-fail-text-color: #FF001D,
        report-date-text-color: #1E70DC,
        report-detail-header-background-color: #E2EDFD,
        withdrawal-seleted-card-border: #D3F3A9,
        notification-unread-text-color: #FF001D,
        notification-unread-title-text-color: #1E70DC,
        notification-modal-label-cell-background: #EAF3FF,
        textarea-border-color: #DCDCDC,
        unread-notification-background-color: #F95858,
        change-password-row-header-text-color: #5D6D7B,
        change-password-confirm-button-background: #6EAEFF,
        change-password-input-error-text-color: #FF001D,
        side-bet-header-background-color: #EAF3FF,
        leng-re-value-hot-background-color: #FB4C93,
        leng-re-value-cold-background-color: #519BFB,
        leng-re-value-normal-background-color: #B7B7B7,
        yi-lou-value-hot-text-color: #FB4C93,
        yi-lou-value-cold-text-color: #519BFB,
        yi-lou-value-normal-text-color: #B7B7B7,
        quick-bet-disabled: #DCDCDC,
        quick-bet-selected: #FFF582DE,
        quick-bet-btn: #D37887,
        quick-bet-input-label:#696969,
        latest-news-footer-button-color: #6EAEFF,
        changlong-question-icon-color: #697885,
        customise-changlong-modal-orange: #FE9C19,
        customise-changlong-modal-template-code-color: #B7B7B7,
        BJK8-ball-light: #96D2E4,
        BJK8-ball-text-color: #09485D,
        BJK8-ball-dark: #214B8A,
        fan-tan-corner-text-color: #D43164,
        fan-tan-label-text-color: #666666,
        hk6-choose-bet-cell:#F7F7F78A,
        game-notice-open-string-text-color: #008000,
        changlong-even-text-color: #00f,
        reminder-text: #FF9E27,
        leng-re-popover-content: #707E8A,
        balance-transfer-loading: #DCDCDC,
        choose-bet-label: #5A6167,
        probability-result-value-label: #5A6167,
        bank-card-bg:#df99a4,
        bank-card-selected-border: #BF6471,
        nav-ico-color:#E9B0D0,
    ),
    'green':(
        font-family: $fontFamily,
        theme-primary-color: #95C15F,
        theme-secondary-color: #8BB25C,
        theme-third-color: #E2EFD1,
        sidebar-betting-title-bg-color: #8AC775,
        sidebar-betting-second-title-bg-color: #FFBE68,
        today-win-bg-color: #5FA1F7,
        footer-more-color: #3D85DF,
        past-result-current-installment-color:#FFDC1C,
        past-result-list-installments-color:#448EED,
        past-result-list-border-color:#DCDCDC,
        content-menu-color: #FFBD68,
        win-lose-amount-color: #1256B1,
        red-num-color: #f96C41,
        blue-num-color: #5EA1F7,
        green-num-color: #8AC775,
        plus-color: #FA7A53,
        header-menu-hover-selected-color: #FCD609,
        sidebar-lottery-color: #5A40FB,
        sidebar-odds-color: #FF4056,
        sidebar-border-color: #CCD3DA,
        header-game-separator: #FFFFFF33,
        table-border-color:#DCDCDC,
        table-header-text-color:#5D6D7B,
        reset-button-color:#8CC878,
        clear-button-color:#EC6941,
        table-cell-color:#F7F7F7, 
        table-odds-color:#F96C41,
        table-square-1:#FFCE00,
        table-square-2:#2C39CA,
        table-square-3:#6F6A66,
        table-square-4:#FF9837,
        table-square-5:#2DCEDE,
        table-square-6:#7655B9,
        table-square-7:#A9A9A9,
        table-square-8:#F54141,
        table-square-9:#792020,
        table-square-10:#4CC32D,
        quick-bet-label-color:#5A6167,
        quick-bet-input-text-color:#B7B7B7,
        quick-bet-border-color:#DCDCDC,
        games-background-color: #EDEDED,
        table-ssc-circle-text-color: #626262,
        table-ssc-circle-border-color: #5EA1F7,
        unsettled-sum-color: #D4e4F9,
        settled-unsettled-row-no:#1E70DC,
        settled-unsettled-installment:#81C269,       
        settled-unsettled-bet-content:#5FA1F6,
        settled-unsettled-bet-odds:#F96C41,
        settled-unsettled-win-money:#5FA1F6,
        settled-unsettled-lose-money:#F96C41,
        pagination-text-color:#666,
        pagination-border-color:#DDD,
        game-menu-title-border: #E4E3E6,
        vipcard-title-color: #5D6D7B,
        chang-long-background-color: #FAFAFA,
        chang-long-installment-color: #F96C41,
        chang-long-border-color: #DCDCDC,
        active-game-background-color: #E6E6E6,
        inactive-bell-background-color: #808887,
        modal-footer-border-color: #DDDDDD,
        reset-default-text-color: #6C727A,
        user-center-button-group-background-color: #F3DDCD,
        user-center-button-background-color: #F95858,
        user-center-top-up-button-background-color: #F9AC00,
        inactive-game-text-color: #666666,
        game-hover-fill-color: #F52C26,
        bet-history-btn-hover:#F9AC00,
        bet-history-arrow-color:#458EED,
        confirm-bet-secondary-color: #EAF3FF,
        confirm-bet-footer-button-color: #6EAEFF,
        confirm-bet-content-installment-text-color: #A5A9AD,
        confirm-bet-table-border-color: #DCDCDC,
        confirm-bet-win-amount: #C1C2C1,
        confirm-bet-balance-amount: #68747F,
        confirm-bet-lottery-text: #68747F,
        bet-items-label-selected-background-color: #FBF7CB,
        bet-items-odds-selected-background-color: #FFF694,
        scrollbar-color: #ADACAC,
        result-red-text:#F96C41,
        result-green-text:#81C269,
        result-blue-text: #5FA1F6,
        probability-border-color:#DCDCDC,
        tab-header-hover-background-color: #CCDEF5,
        shortcut-input-background-color: #FDEFDD,
        shortcut-title-text-color: #77654C,
        shortcut-title-wrapper-color: #C3AF95,
        game-menu-hover-background-color: #1C65BF,
        game-menu-active-text-color: #FFE100,
        quickbet-button-color: #FF9C1C,
        leng-re-option-border-color: #DCDCDC,
        leng-re-inactive-text-color: #B4B4B4,
        leng-re-active-text-color: #EC6941,
        leng-re-checkbox-border-color: #DCDCDC,
        checkbox-option-border-color:#DCDCDC,
        section-label-background-color: #EAF3FF,
        section-label-text-color: #5D6D7B,
        confirm-redemption-background-color: #6EAEFF,
        result-table-header-text:#5D6D7B,
        result-table-row-text:#5A6167,
        result-border:#D2D7E0,
        lottery-rules-border-color: #D2D7E0,
        safety-centre-input-text-color: #444444,
        safety-centre-button-text-color: #555555,
        safety-centre-button-disabled: #E9ECEF,
        betLimitBasicInfoHighlighted: #FFF582,
        balance-log-modal-hover-color: #F0F6FF,
        lottery-rules-subHeader-font-color: #212529,
        lottery-rules-red-font-color: #FF001D,
        error-modal-footer-border-color: #DCDCDC,
        error-modal-text-color: #DD4B4B,
        success-modal-footer-border-color: #DCDCDC,
        success-modal-text-color: #67A145,
        top-up-modal-cancel-order-background-color: #FF9C1C,
        top-up-modal-copy-button-background-color: #F9AB02,
        top-up-modal-confirm-button-background-color: #5DA0F6,
        top-up-modal-content-message-background-color: #ECEDED,
        top-up-modal-content-message-text-color: #677C88,
        top-up-modal-content-label-text-color: #8A8B8B,
        top-up-modal-content-value-text-color: #478EED,
        top-up-modal-content-orderId-text-color: #F95959,
        top-up-modal-content-attention-text-color: #F95959,
        top-up-payment-type-item-border-color: #DCDCDC,
        top-up-payment-type-item-text-color: #555555,
        modal-background-color: #6EAEFF,
        table-hk6-tab-border-color: #5EA1F7,
        checkbox-disabled-text-color: #ABABAB,
        checkbox-disabled-background-color: #E6E5E5,
        third-party-records-search-button-color: #448EED,
        third-party-records-search-button-hover-color: #F9AC00,
        third-party-records-sum-background-color: #F3F3F3,
        third-party-records-sum-positive-text-color: #5FA1F6,
        third-party-records-sum-negative-text-color: #FF001D,
        third-party-records-bet-id-text-color: #5FA1F6,
        bank-card-tips: #B9C3E4,
        bank-card-card-background: #7EAEFF,
        bank-card-card-border: #CCCCCC,
        bank-card-card-name: #4B689A,
        withdrawal-bank-card-bg: #7EAEFF,
        withdrawal-bank-card-border: #CCC,
        withdrawal-bank-card-name: #4B689A,
        withdrawal-type-btn-border: #DCDCDC,
        withdrawal-type-btn-color: #555,
        withdrawal-title: #5D6D7B,
        withdrawal-warning: #FF2B2B,
        transfer-border-refresh: #8AC775,
        transfer-confirm-btn-bg:#6eaeff,
        transfer-text:#5d6d7b,
        transfer-svg-stroke:#5687c6,
        transfer-bg:#eaf3ff,
        transfer-input-border-color: #BFCBD9,
        multiple-type-odds-tips: #5a6167,
        datatable-empty-data-text-color: #C7C7C7,
        transaction-id-text-color: #1E70DC,
        transaction-success-text-color: #81C269,
        transaction-fail-text-color: #FF001D,
        report-date-text-color: #1E70DC,
        report-detail-header-background-color: #E2EDFD,
        withdrawal-seleted-card-border: #D3F3A9,
        notification-unread-text-color: #FF001D,
        notification-unread-title-text-color: #1E70DC,
        notification-modal-label-cell-background: #EAF3FF,
        textarea-border-color: #DCDCDC,
        unread-notification-background-color: #F95858,
        change-password-row-header-text-color: #5D6D7B,
        change-password-confirm-button-background: #6EAEFF,
        change-password-input-error-text-color: #FF001D,
        side-bet-header-background-color: #EAF3FF,
        leng-re-value-hot-background-color: #FB4C93,
        leng-re-value-cold-background-color: #519BFB,
        leng-re-value-normal-background-color: #B7B7B7,
        yi-lou-value-hot-text-color: #FB4C93,
        yi-lou-value-cold-text-color: #519BFB,
        yi-lou-value-normal-text-color: #B7B7B7,
        quick-bet-disabled: #DCDCDC,
        quick-bet-selected: #FFF582DE,
        quick-bet-btn: #95C15F,
        quick-bet-input-label:#696969,
        latest-news-footer-button-color: #6EAEFF,
        changlong-question-icon-color: #697885,
        customise-changlong-modal-orange: #FE9C19,
        customise-changlong-modal-template-code-color: #B7B7B7,
        BJK8-ball-light: #96D2E4,
        BJK8-ball-text-color: #09485D,
        BJK8-ball-dark: #214B8A,
        fan-tan-corner-text-color: #D43164,
        fan-tan-label-text-color: #666666,
        hk6-choose-bet-cell:#F7F7F78A,
        game-notice-open-string-text-color: #008000,
        changlong-even-text-color: #00f,
        reminder-text: #FF9E27,
        leng-re-popover-content: #707E8A,
        balance-transfer-loading: #DCDCDC,
        choose-bet-label: #5A6167,
        probability-result-value-label: #5A6167,
        bank-card-bg:#b5d193,
        bank-card-selected-border:#749648,
        nav-ico-color:#a9d598,
    ),
    'yellow':(
        font-family: $fontFamily,
        theme-primary-color: #EBA717,
        theme-secondary-color: #DD9B11,
        theme-third-color: #FFF8E8,
        sidebar-betting-title-bg-color: #8AC775,
        sidebar-betting-second-title-bg-color: #FFBE68,
        today-win-bg-color: #5FA1F7,
        footer-more-color: #3D85DF,
        past-result-current-installment-color:#FFDC1C,
        past-result-list-installments-color:#448EED,
        past-result-list-border-color:#DCDCDC,
        content-menu-color: #FFBD68,
        win-lose-amount-color: #1256B1,
        red-num-color: #f96C41,
        blue-num-color: #5EA1F7,
        green-num-color: #8AC775,
        plus-color: #FA7A53,
        header-menu-hover-selected-color: #FCD609,
        sidebar-lottery-color: #5A40FB,
        sidebar-odds-color: #FF4056,
        sidebar-border-color: #CCD3DA,
        header-game-separator: #FFFFFF33,
        table-border-color:#DCDCDC,
        table-header-text-color:#5D6D7B,
        reset-button-color:#8CC878,
        clear-button-color:#EC6941,
        table-cell-color:#F7F7F7, 
        table-odds-color:#F96C41,
        table-square-1:#FFCE00,
        table-square-2:#2C39CA,
        table-square-3:#6F6A66,
        table-square-4:#FF9837,
        table-square-5:#2DCEDE,
        table-square-6:#7655B9,
        table-square-7:#A9A9A9,
        table-square-8:#F54141,
        table-square-9:#792020,
        table-square-10:#4CC32D,
        quick-bet-label-color:#5A6167,
        quick-bet-input-text-color:#B7B7B7,
        quick-bet-border-color:#DCDCDC,
        games-background-color: #EDEDED,
        table-ssc-circle-text-color: #626262,
        table-ssc-circle-border-color: #5EA1F7,
        unsettled-sum-color: #D4e4F9,
        settled-unsettled-row-no:#1E70DC,
        settled-unsettled-installment:#81C269,
        settled-unsettled-bet-content:#5FA1F6,
        settled-unsettled-bet-odds:#F96C41,
        settled-unsettled-win-money:#5FA1F6,
        settled-unsettled-lose-money:#F96C41,
        pagination-text-color:#666,
        pagination-border-color:#DDD,
        game-menu-title-border: #E4E3E6,
        vipcard-title-color: #5D6D7B,
        chang-long-background-color: #FAFAFA,
        chang-long-installment-color: #F96C41,
        chang-long-border-color: #DCDCDC,
        active-game-background-color: #E6E6E6,
        inactive-bell-background-color: #808887,
        modal-footer-border-color: #DDDDDD,
        reset-default-text-color: #6C727A,
        user-center-button-group-background-color: #F3DDCD,
        user-center-button-background-color: #F95858,
        user-center-top-up-button-background-color: #F9AC00,
        inactive-game-text-color: #666666,
        game-hover-fill-color: #F52C26,
        bet-history-btn-hover:#F9AC00,
        bet-history-arrow-color:#458EED,
        confirm-bet-secondary-color: #EAF3FF,
        confirm-bet-footer-button-color: #6EAEFF,
        confirm-bet-content-installment-text-color: #A5A9AD,
        confirm-bet-table-border-color: #DCDCDC,
        confirm-bet-win-amount: #C1C2C1,
        confirm-bet-balance-amount: #68747F,
        confirm-bet-lottery-text: #68747F,
        bet-items-label-selected-background-color: #FBF7CB,
        bet-items-odds-selected-background-color: #FFF694,
        scrollbar-color: #ADACAC,
        result-red-text:#F96C41,
        result-green-text:#81C269,
        result-blue-text: #5FA1F6,
        probability-border-color:#DCDCDC,
        tab-header-hover-background-color: #CCDEF5,
        shortcut-input-background-color: #FDEFDD,
        shortcut-title-text-color: #77654C,
        shortcut-title-wrapper-color: #C3AF95,
        game-menu-hover-background-color: #1C65BF,
        game-menu-active-text-color: #FFE100,
        quickbet-button-color: #FF9C1C,
        leng-re-option-border-color: #DCDCDC,
        leng-re-inactive-text-color: #B4B4B4,
        leng-re-active-text-color: #EC6941,
        leng-re-checkbox-border-color: #DCDCDC,
        checkbox-option-border-color:#DCDCDC,
        section-label-background-color: #EAF3FF,
        section-label-text-color: #5D6D7B,
        confirm-redemption-background-color: #6EAEFF,
        result-table-header-text:#5D6D7B,
        result-table-row-text:#5A6167,
        result-border:#D2D7E0,
        lottery-rules-border-color: #D2D7E0,
        safety-centre-input-text-color: #444444,
        safety-centre-button-text-color: #555555,
        safety-centre-button-disabled: #E9ECEF,
        betLimitBasicInfoHighlighted: #FFF582,
        balance-log-modal-hover-color: #F0F6FF,
        lottery-rules-subHeader-font-color: #212529,
        lottery-rules-red-font-color: #FF001D,
        error-modal-footer-border-color: #DCDCDC,
        error-modal-text-color: #DD4B4B,
        success-modal-footer-border-color: #DCDCDC,
        success-modal-text-color: #67A145,
        top-up-modal-cancel-order-background-color: #FF9C1C,
        top-up-modal-copy-button-background-color: #F9AB02,
        top-up-modal-confirm-button-background-color: #5DA0F6,
        top-up-modal-content-message-background-color: #ECEDED,
        top-up-modal-content-message-text-color: #677C88,
        top-up-modal-content-label-text-color: #8A8B8B,
        top-up-modal-content-value-text-color: #478EED,
        top-up-modal-content-orderId-text-color: #F95959,
        top-up-modal-content-attention-text-color: #F95959,
        top-up-payment-type-item-border-color: #DCDCDC,
        top-up-payment-type-item-text-color: #555555,
        modal-background-color: #6EAEFF,
        table-hk6-tab-border-color: #5EA1F7,
        checkbox-disabled-text-color: #ABABAB,
        checkbox-disabled-background-color: #E6E5E5,
        third-party-records-search-button-color: #448EED,
        third-party-records-search-button-hover-color: #F9AC00,
        third-party-records-sum-background-color: #F3F3F3,
        third-party-records-sum-positive-text-color: #5FA1F6,
        third-party-records-sum-negative-text-color: #FF001D,
        third-party-records-bet-id-text-color: #5FA1F6,
        bank-card-tips: #B9C3E4,
        bank-card-card-background: #7EAEFF,
        bank-card-card-border: #CCCCCC,
        bank-card-card-name: #4B689A,
        withdrawal-bank-card-bg: #7EAEFF,
        withdrawal-bank-card-border: #CCC,
        withdrawal-bank-card-name: #4B689A,
        withdrawal-type-btn-border: #DCDCDC,
        withdrawal-type-btn-color: #555,
        withdrawal-title: #5D6D7B,
        withdrawal-warning: #FF2B2B,
        transfer-border-refresh: #8AC775,
        transfer-confirm-btn-bg:#6eaeff,
        transfer-text:#5d6d7b,
        transfer-svg-stroke:#5687c6,
        transfer-bg:#eaf3ff,
        transfer-input-border-color: #BFCBD9,
        multiple-type-odds-tips: #5a6167,
        datatable-empty-data-text-color: #C7C7C7,
        transaction-id-text-color: #1E70DC,
        transaction-success-text-color: #81C269,
        transaction-fail-text-color: #FF001D,
        report-date-text-color: #1E70DC,
        report-detail-header-background-color: #E2EDFD,
        withdrawal-seleted-card-border: #D3F3A9,
        notification-unread-text-color: #FF001D,
        notification-unread-title-text-color: #1E70DC,
        notification-modal-label-cell-background: #EAF3FF,
        textarea-border-color: #DCDCDC,
        unread-notification-background-color: #F95858,
        change-password-row-header-text-color: #5D6D7B,
        change-password-confirm-button-background: #6EAEFF,
        change-password-input-error-text-color: #FF001D,
        side-bet-header-background-color: #EAF3FF,
        leng-re-value-hot-background-color: #FB4C93,
        leng-re-value-cold-background-color: #519BFB,
        leng-re-value-normal-background-color: #B7B7B7,
        yi-lou-value-hot-text-color: #FB4C93,
        yi-lou-value-cold-text-color: #519BFB,
        yi-lou-value-normal-text-color: #B7B7B7,
        quick-bet-disabled: #DCDCDC,
        quick-bet-selected: #FFF582DE,
        quick-bet-btn: #EBA717,
        quick-bet-input-label:#696969,
        latest-news-footer-button-color: #6EAEFF,
        changlong-question-icon-color: #697885,
        customise-changlong-modal-orange: #FE9C19,
        customise-changlong-modal-template-code-color: #B7B7B7,
        BJK8-ball-light: #96D2E4,
        BJK8-ball-text-color: #09485D,
        BJK8-ball-dark: #214B8A,
        fan-tan-corner-text-color: #D43164,
        fan-tan-label-text-color: #666666,
        hk6-choose-bet-cell:#F7F7F78A,
        game-notice-open-string-text-color: #008000,
        changlong-even-text-color: #00f,
        reminder-text: #FF9E27,
        leng-re-popover-content: #707E8A,
        balance-transfer-loading: #DCDCDC,
        choose-bet-label: #5A6167,
        probability-result-value-label: #5A6167,
        bank-card-bg:#efc160,
        bank-card-selected-border:#CCA246,
        nav-ico-color:#f8c785,
    )
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
  
  @function themed($key) {
    @return map-get($theme-map, $key);
  }