@import '../../../scss/base';

.web {
  width:500px;
}

.generalWrapper {
  .modal-header {
    flex:1;
    padding: 0.75rem;
    color: $white;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
    }
  }

  .modal-body {
    height: 8em;
    overflow: auto;
    margin-top: 10px;
  }

  .modal-footer {
    button {
      height: 3em;
      @include themify($themes) {
        background-image: linear-gradient(90deg,themed('theme-gradient-color-start'),themed('theme-gradient-color-end'));
      }
    }
  }
}