@import '../../../../scss/web_base';

.dataTableWrapper {
  width: 100%;
  border-radius: 5px;
  @include themify($themes) {
    border-right: 1px solid themed(table-border-color);
    border-bottom: 1px solid themed(table-border-color);
  }
  .dataTable{
    width: 100%;
    background-color: $white;
    text-align: center;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 4px;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    td,th{
      color: #5d6d7b;
      font-size: 13px;
      font-weight: normal;
      padding: 8px 4px;
      @include themify($themes) {
        border: 1px solid themed(table-border-color);
      }
      border-top: none;
      border-left: none;
    }
    th{
      font-weight: normal !important;
    }
    .tableHeader{
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }
      font-size: 13px;
      font-weight: normal;
      padding: 8px 4px;
    }
    thead {
      tr {
        th {
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    tbody {
      .emptyIcon {
        height: 8em;
        width: 8em;
        margin-top: 30px;
      }
      .emptyTextWrapper {
        height: 5em;
        font-size: 16px;
        @include themify($themes) {
          color: themed(datatable-empty-data-text-color)
        }
      }
      tr {
        td {
          &:last-child {
            border-right: none;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
    .tableBodyRow{
      color: #5A6167;
      cursor: pointer;
      .betOrderNumber{
        text-decoration: underline;
        @include themify($themes){
          color: themed(settled-unsettled-row-no);
        }
      }
      .lotteryName{
          display: block;
      }
      .installment{
        @include themify($themes){
          color: themed(settled-unsettled-installment);
        }
      }
      .content{
        @include themify($themes){
          color: themed(settled-unsettled-bet-content);
        }
      }
      .odds{
        @include themify($themes){
          color: themed(settled-unsettled-bet-odds);
        }
      }
      &:hover{
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
      }
    }
  }
}