@import "../../scss/web_base.scss";

.skeletonWrapper {
  background: #f2f2f2;
  display: inline-block;
  height: 16px;
  border-radius: 4px;
  width: 80%;
  &.is-animated {
    background: linear-gradient(90deg,#F2F2F2 25%,#E6E6E6 37%,#F2F2F2 63%);
    background-size: 400% 100%;
    animation: skeleton-loading 1s ease infinite
  }
}

@keyframes skeleton-loading {
  0% {
      background-position: 100% 50%
  }

  to {
      background-position: 0 50%
  }
}
