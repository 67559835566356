@import '../../../scss/base';

.transactionFilterModalMobile {
  @include themify($themes) {
    font-family: themed(font-family);
  }
  width: 279px;
  .modalHeader{
    line-height: 45px;
    width: 279px;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
    }
    color: $white;
    font-size: 16px;
  }
  .modalContent {
    width: 279px;
    .moreOptionsWrapper{
      margin: 0 15px 0;
      font-size: 16px;
      .categoryOpenPickerWrapper {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        color: #888;
        @include themify($themes) {
          border: 1px solid themed(border-color);
        }
        border-radius: 5px;
        line-height: 16px;
      }
      .statusItem{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        padding: 5px 0 5px;
        color: #888;
        @include themify($themes) {
          border: 1px solid themed(border-color);
        }
        border-radius: 5px;
        margin: 0 2px 0;
        &.active{
          @include themify($themes) {
            color: themed(theme-primary-color);
            border: 1px solid themed(theme-primary-color);
          }
        }
      }
    }
  }
  .modalFooter{
    .confirmButton{
      width: 250px;
    border: none;
      @include themify($themes) {
        border: 0px;
        background-image: linear-gradient(90deg,themed(theme-gradient-color-start),themed(theme-gradient-color-end));

        color: $white;
      }
    }
  }
}