@import '../../../scss/web_base';

.errorModalWrapperWeb {
  width: 500px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding-bottom: 2em;
    .errorCross {
      height: 7em;
      width: 7em;
    }
    .errorText {
      @include themify($themes) {
        color: themed(error-modal-text-color)
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    @include themify($themes) {
      border-top: 1px solid #ddd;
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}
.errorModalWrapperMobile {
  width: 279px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader{
    font-size: 17px;
    line-height: 45px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
      color: $white;
    }
  }
  .modalContent {
    height: 170px;
    font-size: 15px;
    .errorCross{
      height: 80px;
    }
  }
  .modalFooter{
    @include themify($themes) {
      border-top: 1px solid themed(success-modal-footer-border-color)
    }
    .confirmButton{
      width: 100px;
      @include themify($themes) {
        border: 0px;
        background-color: themed('theme-primary-color');
        color: $white;
      }
    }
  }
}