@import '../../../scss/base';

.radioButtonListModalWrapper {
  height: 100%;
  .modal-header {
    justify-content: center;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
    }
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    h5 {
      font-size: 1.2em;
      font-weight: bold;
      color: white !important;
    }
  }
  .modal-body {
    padding: 0;
    flex-direction: column;
    overflow: scroll;
    max-height: 50vh;
    .modalBodyWrapper{
      overflow-y: scroll;
    }
    .row {
      width: 100%;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      margin: 0;
      border-bottom: 1px solid $border;
      font-size: 15px;
      width: 279px;
      .radioButtonItem {
        svg {
          margin-top: 5px;
          font-size: 1.3em;
          @include themify($themes) {
            fill: themed(theme-primary-color);
            stroke: themed(theme-primary-color);
          } 
        }
      }
    }
  }
}