@import '../../../scss/_base';

.mobileBankCardDetailModal{
  @include themify($themes) {
    font-family: themed(font-family);
    width: 279px;
  }
  .modalHeader{
    font-size: 17px;
    line-height: 45px;
    width: 279px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
      color: $white;
    }
  }
  .modalContent {
    font-size: 15px;
    .row{
      width: 308px;
    }
    .errorCross{
      height: 80px;
    }
  }
  .modalFooter{
    .confirmButton{
      width: 100px;
      @include themify($themes) {
        border: 0px;
        background-color: themed('theme-primary-color');
        color: $white;
      }
    }
  }
  
}