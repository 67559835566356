@import '../../../scss/web_base';

.confirmBetWrapperWeb {
  @include themify($themes) {
    font-family: themed(font-family);
  }
  div {
    margin: 0;
  }
  .modalHeader {
    line-height: 36px;
    text-align: center;
    color: $white;
    font-size: 15px;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    justify-content: center;
    }
  .modalContent {
    font-size: 16px;
    height: max-content;
    .lotteryWrapper {
      @include themify($themes) {
        color: themed(confirm-bet-lottery-text);
      }
    }
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .lotteryListWrapper {
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;
      
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        @include themify($themes){
          background: themed(scrollbar-color);
        }
        border-radius: 5px;
      }
    }
    .row {
      .col-12 {
        padding: 0;
      }
      width: 100%;
      table, th, td {
        @include themify($themes) {
          border: 1px solid themed(confirm-bet-table-border-color);
        }
      }
      table {
        width: 100%;
        thead {
          @include themify($themes) {
            background-color: themed(theme-third-color);
          }
          font-size: 12px;
          text-align: center;
          td {
            padding: 8px 4px;
            &.ballNumCol {
              text-align: center;
              width: 110px
            }
            &.oddsCol {
              width: 68px;
            }
            &.amountCol {
              width: 134px;
            }
            &.confirmCol {
              width: 41px;
            }
          }
        }
        tbody {
          tr {
            td {
              text-align: center;
              .checkboxInput {
                position: initial;
                margin: 0;
              }
              &.ballNumCol {
                font-size: 12px;
              }
              &.oddsCol {
                @include themify($themes) {
                  color: themed(table-odds-color);
                }
                font-size: 12px;
              }
              &.amountCol {
                font-size: 12px;
                padding: 10px 20px;
                input {
                  height: 25px;
                  margin: 0 auto;
                  outline: none;
                  border-radius: 2px;
                  padding: 2px;
                  font-size: 12px;
                  border: 1px solid #dcdcdc;
                  -webkit-transition: all 300ms ease 0s;
                  transition: all 300ms ease 0s;
                  color: #5d6d7b;
                }
              }
              &.deleteCol {
                width: 41px;
                .trashIcon {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .installmentWrapper {
      @include themify($themes) {
        color: themed(confirm-bet-content-installment-text-color);
      }
      .col-6 {
        padding: 0;
      }
    }
    .totalWrapper {
      font-size: 16px;
      @include themify($themes) {
        color: themed( confirm-bet-balance-amount);
      }
      .col-6 {
        padding: 0;
      }
      .amountText {
        color: $red;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .winAmountWrapper {
      font-size: 14px;
      @include themify($themes) {
        color: themed(confirm-bet-win-amount);
      }
    }
  }
  .modalFooter {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    @include themify($themes) {
      background-color: themed(theme-third-color);
    }
    .confirmBtn {
      border: 0;
      height: 36px;
      @include themify($themes) {
        background-color: themed(theme-primary-color);
      }
    }
  }
}