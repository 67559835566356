@import '../../../scss//web_base.scss';

    .loginAndRegister{
        font-size: 16px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 2147483647s;
        }


        .loginBox{
            box-shadow: 0px 3px 6px #00000029;
            width: 446px;
            background-color: white;
            padding: 30px 40px;
            border-radius: 20px;
            .loginTitle{
                @include themify($themes){
                    color: themed(theme-secondary-color);
                }
                font-weight: bold;
                margin-top: 10px;
                margin-bottom: 30px;
            }
            .loginBoxItem{
                margin-bottom: 20px;
                height: 45px;
                border-radius: 30px;
                width: 100%;
                border: 1px solid #ccc;
                display: flex;
                align-items: center;
                .loginButton{
                    @include themify($themes){
                        background-color: themed(theme-primary-color);
                    }
                    &:hover{
                        @include themify($themes){
                            background-color: themed(theme-secondary-color) !important;
                        }
                    }
                    height: 45px !important;
                    width: 100%;
                    border-radius: 30px;
                    border: none !important;
                    font-weight: bold;
                }
                &>div:first-child{
                    font-weight: bold;
                    padding-left: 20px;
                    @include themify($themes){
                        color: themed(theme-primary-color);
                    }
                    width: 30%;
                }
                input{
                    flex: 1;
                    border: none !important;
                    background-color: transparent !important;
                    &:focus{
                        box-shadow: none !important;
                    }
                }
                #captchaImg{
                    height: 43px !important;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }
            }
        }
    }
    
    .loginRegisterWarpper{ 
        width: 100%;
        height: 100%;
        background: url("../../../assets//image/login_newyear.png") no-repeat center; 
        background-size:100% 100%;
        background-attachment: fixed;
    }