@import '../../../scss/web_base';

.presetAmountWrapper {
  min-width: 200px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  &.Mobile{
    @include themify($themes) {
      width: 279px;
    }
  }
  .amountClear{
    cursor: pointer;
    &.Mobile{
      font-size: 24px;
      margin-left: 10px;
    }
    &.Web{
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(50%,-50%);
    font-size: 24px;
    }

    @include themify($themes) {
      color: themed(theme-primary-color);
    }
  }
  .modalHeader {
    display: flex;
    height: 40px;
    padding: 0;
    color: $white;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
    }
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    background-color: $white;
    input {
      text-align: center;
      box-shadow: none;
      color: $black;
      &:focus {
        @include themify($themes) {
          border: 1px solid themed(theme-primary-color);
        }
      }
    }

    &.Mobile{
      display: block;   
      &>div{
        display: flex;
        align-items: center;
        padding: 0px;
        width: 70%;
        margin: 10px auto;
      } 
    }

    &.Web{
      display: flex;
      padding: 20px;
      &>div{
        max-width: 70px;
        margin: 0px 5px;
        input {
          height: 30px;
          font-size: 13px;
        }

      }
    }
  }
  .modalFooter {
    @include themify($themes) {
      border-top: 1px solid themed(modal-footer-border-color);
    }
    background-color: $white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .saveBtn {
      width: 84px;
      height: 28px;
      color: $white;
      border-radius: 5px;
      border: 0;
      outline: none;
      font-size: 12px;
      font-weight: bold;
      @include themify($themes) {
        border: 1px solid themed(theme-primary-color);
        background-color: themed(theme-primary-color);
      }
    }

  }
}