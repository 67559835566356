@import '../../../scss/web_base';

.transactionRecordModalWrapper{
  width: 450px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  div {
    margin: 0;
  }
  .modalHeader {
    line-height: 35px;
    color: $white;
    font-size: 16px;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    .lotteryWrapper {
      @include themify($themes) {
        color: themed(confirm-bet-lottery-text);
      }
    }
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .item{
      border-right: none;
      border-bottom: none;
      @include themify($themes) {
        border-top: 1px solid themed(past-result-list-border-color);
        border-left: 1px solid themed(past-result-list-border-color);
      }
      table {
        width: 100%;
        box-sizing: border-box;
        text-indent: initial;
        border-spacing: 2px;
        border-color: $grey;
        tbody {
          display: table-row-group;
          vertical-align: middle;
          border-color: inherit;
          tr {
            cursor: pointer;
            transition: all 300ms ease 0s;
            td {
              font-size: 12px;
              padding: 8px 4px;
              @include themify($themes) {
                border: 1px solid themed(past-result-list-border-color);
                border-top: none;
                border-left: none;
              }
              text-align: center;
              @include themify($themes) {
                color: themed(vipcard-title-color);
              }
              &:first-child {
                text-align: left;
                width: 120px;
                padding-left: 30px;
                font-weight: bold;
                @include themify($themes) {
                  background-color: themed(theme-third-color);
                }
              }
              &.successText {
                @include themify($themes) {
                  color: themed(transaction-success-text-color)
                }
              }
              &.failText {
                @include themify($themes) {
                  color: themed(transaction-fail-text-color)
                }
              }
            }
          }
        }
      }
    }
  }
  .modalFooter {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    @include themify($themes) {
      background-color: themed(theme-third-color);
    }
    .closeBtn {
      border: 0;
      width: 120px;
      height: 36px;
      @include themify($themes) {
        background-color: themed(theme-primary-color);
      }
    }
  }
}