@import '../../../scss/web_base';

.themeColorModalWrapperMobile {
  @include themify($themes) {
    font-family: themed(font-family);
    width: 100%;
  }
  .modalContent {
    min-width: 80vw;
    .row{
      width: 100%;
    }
    .bannerImage {
      width: 30vw;
    }
  }
  .modalFooter{
    width: 100%;
    .confirmButton{
      width: 100px;
      @include themify($themes) {
        border: 0px;
        background-color: themed(theme-primary-color);
        color: $white;
      }
    }
  }
  .themeSelectorWrapper {
    height: 4vh;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7vw;
      height: 7vw;
      border-radius: 1vw;
      margin-left: 7px;
      margin-right: 7px;
      cursor: pointer;
      &.blue {
        background-color: $themeBlue;
      }
      &.red {
        background-color: $themeRed;
      }
      &.green {
        background-color: $themeGreen;
      }
      &.yellow {
        background-color: $themeYellow;
      }
    }
  }
}