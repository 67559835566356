@import '../../../scss/web_base';

.betItemDetailModalWrapper{
  width: 850px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  div {
    margin: 0;
  }
  .modalHeader {
    line-height: 35px;
    color: $white;
    font-size: 16px;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    table {
      width: 100%;
      border-left: 1px solid #dcdcdc;
      border-top: 1px solid #dcdcdc;
      tr {
        cursor: pointer;
        -webkit-transition: all 300ms ease 0s;
        transition: all 300ms ease 0s;
        td {
          font-size: 12px;
          padding: 8px 4px;
          border: 1px solid #dcdcdc;
          border-top: none;
          border-left: none;
          text-align: center;
          color: #5d6d7b;
          &.label {
            @include themify($themes) {
              background-color: themed(theme-third-color);
            }
          }
          &:not(.label):hover {
            @include themify($themes) {
              background-color: themed(theme-third-color);
            }
          }
        }
      }
    }
  }
  .modalFooter {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    @include themify($themes) {
      background-color: themed(theme-third-color);
    }
    .confirmBtn {
      border: 0;
      width: 120px;
      height: 36px;
      @include themify($themes) {
        background-color: themed(theme-primary-color);
      }
    }
  }
}