@import '../../../scss/web_base';

.thirdPartyPanelWrapperWeb {
  width: 700px !important;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding: 15px 20px;
    .thirdPartyFormWrapper {
      .row {
        padding: 15px 5px;
        @include themify($themes) {
          border-top: 1px solid themed(table-border-color);
          border-left: 1px solid themed(table-border-color);
          border-right: 1px solid themed(table-border-color);
        }
        &:first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          @include themify($themes) {
            border-bottom: 1px solid themed(table-border-color);
          }
        }
      }
      .refreshButton {
        height: 35px;
        font-size: 15px;
        @include themify($themes) {
          color: themed(transfer-border-refresh);
          border: 1px solid themed(transfer-border-refresh);
        }
        &:hover {
          color: $white;
          @include themify($themes) {
            background-color: themed(transfer-border-refresh);
          }
        }
      }
      .recycleButton {
        height: 35px;
        font-size: 15px;
        @include themify($themes) {
          color: themed(theme-primary-color);
          border: 1px solid themed(theme-primary-color);
        }
        &:hover {
          color: $white;
          @include themify($themes) {
            background-color: themed(theme-primary-color);
          }
        }
      }
      .transferInButton,
      .topUpButton {
        height: 35px;
        font-size: 15px;
        @include themify($themes) {
          background-color: themed(theme-primary-color);
          border: 1px solid themed(theme-primary-color);
        }
      }
      .inputMoney{
        &::placeholder {
          @include themify($themes) {
            font-size: 14px;
            color: themed(transfer-input-border-color);
          }
        }
      }
      .errorMessage {
        color: $red;
        font-size: 15px;
      }
      .amountText {
        @include themify($themes) {
          color: themed(theme-primary-color);
        }
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}
.successModalWrapperMobile {
  @include themify($themes) {
    font-family: themed(font-family);
    width: 279px;
  }
  .modalHeader{
    line-height: 45px;
    width: 279px;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    @include themify($themes) {
      background-color: themed('theme-primary-color');
      color: $white;
    }
  }
  .modalContent {
    .row{
      width: 308px;
    }
    .successTick{
      height: 80px;
    }
  }
  .modalFooter{
    .confirmButton{
      width: 100px;
      @include themify($themes) {
        border: 0px;
        background-color: themed('theme-primary-color');
        color: $white;
      }
    }
  }
}