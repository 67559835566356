$white: #FFFFFF;
$grey: #808080;
$table-cell-background-color: #CBCBCB;
$border-grey: #DDD;
$border: #E4E3E6;
$black: #000000;
$red: #FF0000;
$ballRed: #EC6941;
$ballBlue: #5EA1F7;
$ballGreen: #80C269;
$ballYellow: #F5E204;
$fontFamily: Helvetica,Tahoma, Arial,"PingFang SC",STXihei,"Microsoft yahei","WenQuanYi Micro Hei",sans-serif;

$themes: (
  'blue': (
    theme-primary-color: #146EE2,
    theme-secondary-color: #3D85DF,
    theme-third-color: #EAF3FF,
    app-auth-background-color: #F8F9FE,
    app-background-color:#F1F1F1,
    font-family: $fontFamily,
    theme-gradient-color-start:#3271FF,
    theme-gradient-color-end:#70A3FF,
    test-play-gradient-color-start: #EEB13C,
    test-play-gradient-color-end: #FFCB55,
    nav-link-inactive-color:#8E8E8F,
    navigation-box-shadow-color:#DAE0E5,
    withdrawal-balance-wrapper-color: #FFFFFF,
    withdrawal-form-label-color: #A5ADBA,
    panel-background-color: #FAFAFA,
    summary-background-color: #D3E6FF,
    place-holder-text-color: #949494,
    customer-service-icon-color: #909090,
    home-page-background-color: #F7F7F7,
    inactive-dot-color: #D3DCD7,
    safety-center-label-color: #979797,
    rules-sub-header-color: #DCDCDC,
    forget-password-hint-text-color: #909090,
    button-disabled: #D8D8D8,
    win-text-color: #55AD00,
    lose-text-color: #FE5A00,
    error-input: #FE5A00,
    profile-drawer-safety-center: #80B2EF,
    profile-drawer-topup-start: #FF9E27,
    profile-drawer-topup-end: #FFBF2D,
    profile-drawer-withdraw-start: #01B9C4,
    profile-drawer-withdraw-end: #00E2E4,
    border-color:#DCDCDC,
    game-subgame-item-bg-color: #E8F2FF,
    table-square-1:#FFCE00,
    table-square-2:#2C39CA,
    table-square-3:#6F6A66,
    table-square-4:#FF9837,
    table-square-5:#2DCEDE,
    table-square-6:#7655B9,
    table-square-7:#A9A9A9,
    table-square-8:#F54141,
    table-square-9:#792020,
    table-square-10:#4CC32D,
    input-border: #CED4DA,
    result-red-text:#F96C41,
    result-green-text:#81C269,
    result-blue-text: #5FA1F6,
    transaction-text: #2161B3,
  ),
  'red': (
    theme-primary-color: #F2594C,
    theme-secondary-color: #C87583,
    theme-third-color: #FEF4F6,
    app-auth-background-color: #F8F9FE,
    app-background-color:#F1F1F1,
    font-family: $fontFamily,
    theme-gradient-color-start:#3271FF,
    theme-gradient-color-end:#70A3FF,
    test-play-gradient-color-start: #EEB13C,
    test-play-gradient-color-end: #FFCB55,
    nav-link-inactive-color:#8E8E8F,
    navigation-box-shadow-color:#DAE0E5,
    withdrawal-balance-wrapper-color: #FFFFFF,
    withdrawal-form-label-color: #A5ADBA,
    panel-background-color: #FAFAFA,
    summary-background-color: #D3E6FF,
    place-holder-text-color: #949494,
    customer-service-icon-color: #909090,
    home-page-background-color: #F7F7F7,
    inactive-dot-color: #D3DCD7,
    safety-center-label-color: #979797,
    rules-sub-header-color: #DCDCDC,
    forget-password-hint-text-color: #909090,
    button-disabled: #D8D8D8,
    win-text-color: #55AD00,
    lose-text-color: #FE5A00,
    error-input: #FE5A00,
    profile-drawer-safety-center: #80B2EF,
    profile-drawer-topup-start: #FF9E27,
    profile-drawer-topup-end: #FFBF2D,
    profile-drawer-withdraw-start: #01B9C4,
    profile-drawer-withdraw-end: #00E2E4,
    border-color:#DCDCDC,
    game-subgame-item-bg-color: #E8F2FF,
    table-square-1:#FFCE00,
    table-square-2:#2C39CA,
    table-square-3:#6F6A66,
    table-square-4:#FF9837,
    table-square-5:#2DCEDE,
    table-square-6:#7655B9,
    table-square-7:#A9A9A9,
    table-square-8:#F54141,
    table-square-9:#792020,
    table-square-10:#4CC32D,
    input-border: #CED4DA,
    result-red-text:#F96C41,
    result-green-text:#81C269,
    result-blue-text: #5FA1F6,
    transaction-text: #2161B3,
  ),
  'green': (
    theme-primary-color: #63A62C,
    theme-secondary-color: #8BB25C,
    theme-third-color: #E2EFD1,
    app-auth-background-color: #F8F9FE,
    app-background-color:#F1F1F1,
    font-family: $fontFamily,
    theme-gradient-color-start:#3271FF,
    theme-gradient-color-end:#70A3FF,
    test-play-gradient-color-start: #EEB13C,
    test-play-gradient-color-end: #FFCB55,
    nav-link-inactive-color:#8E8E8F,
    navigation-box-shadow-color:#DAE0E5,
    withdrawal-balance-wrapper-color: #FFFFFF,
    withdrawal-form-label-color: #A5ADBA,
    panel-background-color: #FAFAFA,
    summary-background-color: #D3E6FF,
    place-holder-text-color: #949494,
    customer-service-icon-color: #909090,
    home-page-background-color: #F7F7F7,
    inactive-dot-color: #D3DCD7,
    safety-center-label-color: #979797,
    rules-sub-header-color: #DCDCDC,
    forget-password-hint-text-color: #909090,
    button-disabled: #D8D8D8,
    win-text-color: #55AD00,
    lose-text-color: #FE5A00,
    error-input: #FE5A00,
    profile-drawer-safety-center: #80B2EF,
    profile-drawer-topup-start: #FF9E27,
    profile-drawer-topup-end: #FFBF2D,
    profile-drawer-withdraw-start: #01B9C4,
    profile-drawer-withdraw-end: #00E2E4,
    border-color:#DCDCDC,
    game-subgame-item-bg-color: #E8F2FF,
    table-square-1:#FFCE00,
    table-square-2:#2C39CA,
    table-square-3:#6F6A66,
    table-square-4:#FF9837,
    table-square-5:#2DCEDE,
    table-square-6:#7655B9,
    table-square-7:#A9A9A9,
    table-square-8:#F54141,
    table-square-9:#792020,
    table-square-10:#4CC32D,
    input-border: #CED4DA,
    result-red-text:#F96C41,
    result-green-text:#81C269,
    result-blue-text: #5FA1F6,
    transaction-text: #2161B3,
  ),
  'yellow': (
    theme-primary-color: #FFA938,
    theme-secondary-color: #DD9B11,
    theme-third-color: #FFF8E8,
    app-auth-background-color: #F8F9FE,
    app-background-color:#F1F1F1,
    font-family: $fontFamily,
    theme-gradient-color-start:#3271FF,
    theme-gradient-color-end:#70A3FF,
    test-play-gradient-color-start: #EEB13C,
    test-play-gradient-color-end: #FFCB55,
    nav-link-inactive-color:#8E8E8F,
    navigation-box-shadow-color:#DAE0E5,
    withdrawal-balance-wrapper-color: #FFFFFF,
    withdrawal-form-label-color: #A5ADBA,
    panel-background-color: #FAFAFA,
    summary-background-color: #D3E6FF,
    place-holder-text-color: #949494,
    customer-service-icon-color: #909090,
    home-page-background-color: #F7F7F7,
    inactive-dot-color: #D3DCD7,
    safety-center-label-color: #979797,
    rules-sub-header-color: #DCDCDC,
    forget-password-hint-text-color: #909090,
    button-disabled: #D8D8D8,
    win-text-color: #55AD00,
    lose-text-color: #FE5A00,
    error-input: #FE5A00,
    profile-drawer-safety-center: #80B2EF,
    profile-drawer-topup-start: #FF9E27,
    profile-drawer-topup-end: #FFBF2D,
    profile-drawer-withdraw-start: #01B9C4,
    profile-drawer-withdraw-end: #00E2E4,
    border-color:#DCDCDC,
    game-subgame-item-bg-color: #E8F2FF,
    table-square-1:#FFCE00,
    table-square-2:#2C39CA,
    table-square-3:#6F6A66,
    table-square-4:#FF9837,
    table-square-5:#2DCEDE,
    table-square-6:#7655B9,
    table-square-7:#A9A9A9,
    table-square-8:#F54141,
    table-square-9:#792020,
    table-square-10:#4CC32D,
    input-border: #CED4DA,
    result-red-text:#F96C41,
    result-green-text:#81C269,
    result-blue-text: #5FA1F6,
    transaction-text: #2161B3,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}