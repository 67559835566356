@import '../../../scss/web_base';

.gameNoticeWrapperWeb {
  width: 500px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding-bottom: 2em;
    .exclamationIcon {
      height: 7em;
      width: 7em;

      circle{
          @include themify($themes){
            fill: themed(theme-primary-color) !important;
          }
        }
    }
    .lotteryClosedTitle {
      font-size: 20px;
      font-weight: bold;
    }
    .nextDrawTime {
      @include themify($themes) {
        color: themed(game-notice-open-string-text-color);
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      border-top: 1px solid themed(success-modal-footer-border-color)
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
    .cancelButton {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      background-color: $white;
      @include themify($themes){
        color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
      &:hover {
        background-color: $white;
      }
    }
  }
}

.gameNoticeWrapperMobile{
  @include themify($themes) {
    font-family: themed(font-family);
    width: 279px;
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    .exclamationIcon {
      height: 7em;
      width: 7em;

      circle{
          @include themify($themes){
            fill: themed(theme-primary-color) !important;
          }
        }
    }
    .lotteryClosedTitle {
      font-size: 16px;
      font-weight: bold;
    }
    .nextDrawTime {
      @include themify($themes) {
        color: themed(game-notice-open-string-text-color);
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      border-top: 1px solid themed(success-modal-footer-border-color)
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
    .cancelButton {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      background-color: $white;
      @include themify($themes){
        color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
      &:hover {
        background-color: $white;
      }
    }
  }
}