@import '../../../scss/web_base';

.notificationModalWrapper{
  width: 400px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  div {
    margin: 0;
  }
  .modalHeader {
    line-height: 35px;
    color: $white;
    font-size: 16px;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    background-color: $white;
    padding: 10px;
    table {
      width: 100%;
      td,th{
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        padding: 8px 4px;
        @include themify($themes) {
          border: 1px solid themed(table-border-color);
          color: themed(table-header-text-color);
        }
        border-top: none;
        border-left: none;
        &.label {
          @include themify($themes) {
            background-color: themed(notification-modal-label-cell-background);
          }
        }
        &.content {
          text-align: left;
          padding: 20px;
        }
      }
    }
  }
  .modalFooter {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    @include themify($themes) {
      background-color: themed(theme-third-color);
    }
    .confirmBtn {
      border: 0;
      width: 120px;
      height: 36px;
      @include themify($themes) {
        background-color: themed(theme-primary-color);
      }
    }
  }
}