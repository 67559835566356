@import '../../../scss/base';

.loadingWrapper_mobile {
  width: 279px;
  .refreshrotate{
    animation:keyFrames 2s linear 0s infinite;
  }
  @keyframes keyFrames{
    0%   {transform:rotate(0deg)}
    8%   {transform:rotate(30deg)}
    16.7%   {transform:rotate(60deg)}
    25%  {transform: rotate(90deg);}
    33.3%  {transform: rotate(120deg);}
    41.7%  {transform: rotate(150deg);}
    50%  {transform: rotate(180deg);}
    58%  {transform: rotate(210deg);}
    66.7% {transform: rotate(240deg);}
    75% {transform: rotate(270deg);}
    83.3% {transform: rotate(300deg);}
    91.7% {transform: rotate(330deg);}
    100% {transform: rotate(360deg);}
  }
  
  .loading-icon-style{
    @include themify($themes) {
      color: themed('theme-primary-color');
    }
  }
  
  .loading-text-style{
    color: 'black';
    font-size: 1.3em;
  }
}
.loadingWrapper_web {
  width: 500px;
  height: 300px;
  .refreshrotate{
    animation:keyFrames 2s linear 0s infinite;
  }
  @keyframes keyFrames{
    0%   {transform:rotate(0deg)}
    8%   {transform:rotate(30deg)}
    16.7%   {transform:rotate(60deg)}
    25%  {transform: rotate(90deg);}
    33.3%  {transform: rotate(120deg);}
    41.7%  {transform: rotate(150deg);}
    50%  {transform: rotate(180deg);}
    58%  {transform: rotate(210deg);}
    66.7% {transform: rotate(240deg);}
    75% {transform: rotate(270deg);}
    83.3% {transform: rotate(300deg);}
    91.7% {transform: rotate(330deg);}
    100% {transform: rotate(360deg);}
  }
  
  .loading-icon-style{
    @include themify($themes) {
      color: themed('theme-primary-color');
    }
  }
  
  .loading-text-style{
    color: 'black';
    font-size: 1.3em;
  }
}